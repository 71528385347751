<template>
    <div class="row">
    <div class="col-sm-6-bottom">
      <label for="commentInput" class="h2-text">Комментарий к бронированию</label>
      <input v-model="commentInput" type="textarea" id="commentInput" ref="commentInput" placeholder="Добавьте комментарий">
      <div class="form-check" style="padding-block: 10px;">
        <input v-model="policyCheck"  class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
        <label class="form-check-label" for="flexCheckDefault1" style="font-weight: 600;font-size: medium;">
          Согласен с <a href="#" class="link-light">Политика обработки персональных данных</a>
        </label>
      </div>
      <div class="form-check">
        <input v-model="oferCheck"  class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
        <label class="form-check-label" for="flexCheckDefault2" style="font-weight: 600;font-size: medium;">
          Согласен с <a href="#" class="link-light">Договор публичной оферты</a>
        </label>
        <span ref="errorf" class="error-of" id="error_oferta">Подтвердите согласие с условиями</span>
      </div>
      <button id="button_post" v-on:click="pressed()" type="button" class="book-button">Забронировать {{ priceBook }}</button>
    </div>
    <div class="col-sm-6-bottom-img">
      <img src="../assets/styles/images/logo1.png" alt="Forest Hills"><br>
    </div>
    <div class="col-sm-6-bottom-ipm"></div>
    <div class="col-sm-6-bottom-logoipm" style="
        display: flex;
        justify-content: center;
    ">
                      <a href="https://www.ipmsol.ru/"><img src="../assets/styles/images/ipm.png" alt="IPM" style="height: 23px;"></a><p style="font-size: small;">Сделано в ООО "IPM"</p>
    </div>
  </div>
</template>


<script>

export default {
  name: 'FooterForm',
  props: ['bookPrice'],
  data() {
    return {
      commentInput: null,
      policyCheck: false,
      oferCheck: false,
      priceBook: null,
    }
  },
  watch: {
    bookPrice(price) {
      this.priceBook = price + " ₽"
    }
  },
  methods:{
    pressed(){
      if (this.policyCheck == false || this.oferCheck == false){
        this.$refs.errorf.className = "error-of viserr";
      }else{
        this.$refs.errorf.className = "error-of";

        this.$emit('btn-pressed',this.commentInput);
      }
    }
  }
}
</script>

<style scoped>
  .viserr{
    display: block;
  }
</style>
