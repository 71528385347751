
<template>
    <div class="input-wrapper-head">
        <button type="button" id="timepicker_show" class="time-picker-button" v-on:click="toogleTimes">
        <span>{{ selectedTime }}</span>
        <span class="arrow-icon">&#9662;</span>
        </button>
    </div>
    <div class="time-picker-container.show">
        <div v-if="showTime" class="time-interval-dropdown">
        <div v-if="hours < 12" v-on:click="selectInterval('09:00-12:00')" id="time-interval1" class="time-interval" dataInterval="09:00-12:00">09:00-12:00</div>
        <div v-if="hours < 15" v-on:click="selectInterval('12:00-15:00')" id="time-interval2" class="time-interval" dataInterval="12:00-15:00">12:00-15:00</div>
        <div v-if="hours < 19" v-on:click="selectInterval('15:00-19:00')" id="time-interval3" class="time-interval" dataInterval="15:00-19:00">15:00-19:00</div>
        <div v-if="hours >= 19" class="time-interval">Нет доступных ти-таймов</div>
        </div>
        <div v-if="showRows" class="row-times show">
              <div v-for="(item,index) in users" :key="item.id" class="col"><div v-bind:id="`col-${index}`" ref="users" v-on:click="selectTime(index)" :class="[colClass, 'col-elem']">{{item.getTime()}}<div><FourFree v-for="n in (4-item.getVal())" :key="n.id"/></div></div></div>
        </div>
    </div>
</template>

<script>
import { BookingServiceClient } from '../booking_service_grpc_web_pb.js';
import { AvailableRequest } from '../booking_service_pb.js';
import FourFree from './FourFree.vue';

export default {
  el: '#app',
  components: {
    FourFree
  },
  emits: ["timeSelected"], // <--- add this line
  setup(_,{ emit }) {
    emit("timeSelected")
  },
  data() {
    return { 
      selectedTime: "Выберите время",
      colClass: null,
      users: null,
      showTime: (window.innerWidth < 500),
      showRows: (window.innerWidth > 500),
      hours: new Date().getHours(),
      formatDate: function(date){
        if (date.toLocaleDateString("en-US") != new Date().toLocaleDateString("en-US")){
          this.hours = 1
        }else{
          this.hours = new Date().getHours()
        }
        let day = date.getDate();
        let month = date.getMonth()+1;
        if (month < 10){
          month = "0" + month
        }
        if (day < 10){
          day = "0" + day
        }
        let year = date.getFullYear();
        let format1 = day + "." + month + "." + year;
        return format1
      },
    }
  },
  mounted() {
    var today = new Date();
    let dayte = this.formatDate(today)
    this.fetchAvailableTimes(dayte,"9:00-18:50");
  },
  props: ['selectedDate'],
  watch: {
    selectedDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.targetDay = this.formatDate(newDate)
        switch (window.innerWidth > 500) {
          case true:
            this.fetchAvailableTimes(this.targetDay,"9:00-18:50");
            break;
          case false:
            this.showTime = true;
            this.showRows = false;
            break;
          default:
            break;
        }

      }
    }
  },
  methods: {
  toogleTimes(){
    this.showRows = false;
    this.showTime = true;
  },
  selectTime(index){
    this.$refs.users.forEach(element => {
      element.className = "col-elem";
    });
    this.$refs.users[index].className = "col-elem ng-color";
    this.$emit('timeSelected', this.$refs.users[index].textContent);
    this.showRows = (window.innerWidth > 500);
    this.selectedTime = this.$refs.users[index].textContent

  },
  selectInterval(interval){
    this.fetchAvailableTimes(this.targetDay,interval);
    this.showTime = false;
    this.showRows = true;
    console.log(this.showRows);
  },
  fetchAvailableTimes(selDate, interval) {
    const client = new BookingServiceClient('https://peterhofgolf.tee-time.ru');
    
    let request = new AvailableRequest();
    request.setSelectedDate(selDate || this.formatDate(new Date()) );
    request.setInterval(interval);

    client.sendAvailable(request, {}, (err, response) => {
      if (err) {
        console.error("gRPC error:", err);
      } else {
        this.processTimeSlots(response.getTimeSlotsList());
      }
    });
  },
  processTimeSlots(timeSlots) {
    this.users = timeSlots;
  },
}


}
</script>

<style scoped>
.ng-color{
  background-color: rgb(114, 205, 114);}
</style>
